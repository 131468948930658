(function () {
    app.controller('TransactionStepCtrl', [
        "$scope", '$timeout',
        function ($scope, $timeout) {
            $scope.transaction_step = {};
            $scope.data_value = '';
            $scope.is_encrypt = false;
            $scope.data_attributes = [];
            $scope.is_show_key_value = false;
            $scope.is_key_duplicate = false;
            $scope.url_secure_valid = true;
            $scope.url_valid = true;

            $scope.init = function () {
                $scope.transaction_step.data_object_value = JSON.parse($scope.transaction_step.data_object_value);

                if( !$scope.transaction_step.parameter_type)
                    $scope.transaction_step.parameter_type = 0;

                if( $.trim($scope.transaction_step.parameter_name) == '')
                    $scope.transaction_step.parameter_name = null;

                if(!$scope.is_restful_api){

                    if ( $.trim($scope.show_action_attribute_value) != '' && $.trim($scope.transaction_step.action_type ) != '' &&
                        $scope.show_action_attribute_value.indexOf($scope.transaction_step.action_type) != -1 &&
                        $scope.transaction_step.data_object_value != null && $scope.transaction_step.data_object_value != {} &&
                        $scope.action_attribute_values[$scope.transaction_step.action_type]['position'] != null &&
                        $.trim($scope.transaction_step.data_object_value.position ) != '') {
                        $scope.transaction_step.data_object_value.position.x = parseInt($scope.transaction_step.data_object_value.position.x);
                        $scope.transaction_step.data_object_value.position.y = parseInt($scope.transaction_step.data_object_value.position.y);
                    }

                    if($scope.show_index_value.indexOf($scope.transaction_step.action_type) != -1){
                        $scope.transaction_step.data_value = parseInt($scope.transaction_step.data_value);
                    }
                    
                    if($scope.transaction_step.action_type == 'set_screen_size'){
                        $scope.transaction_step.data_object_value.horizontal = parseInt($scope.transaction_step.data_object_value.horizontal);
                        $scope.transaction_step.data_object_value.vertical = parseInt($scope.transaction_step.data_object_value.vertical);
                    }
                }else{

                    if($scope.api_action_show_key_value.indexOf($scope.transaction_step.action_type) != -1){
                        $scope.is_show_key_value = true;

                        if(JSON.stringify($scope.transaction_step.data_object_value) != '{}'){
                            var data = $scope.transaction_step.data_object_value;

                            if($scope.transaction_step.action_type == 'set_body_parameters'){
                                $scope.is_show_key_value = $scope.transaction_step.data_object_value.body_type != null && $scope.transaction_step.data_object_value.body_type != $scope.body_types['raw json']
                                data = $scope.transaction_step.data_object_value.data;
                            }

                            Object.keys(data).forEach(function(key)
                            {
                                $scope.data_attributes.push(data[key]);
                            });

                            $timeout( function () {
                                angular.forEach($scope.data_attributes, function(value, key) {
                                    if(value.parameter_id){
                                        value.parameter_id = parseInt(value.parameter_id);
                                        if(value.parameter_type == $scope.from_params_data.APP){
                                            $scope.changeParam($scope.app_params, value.parameter_id, key);
                                        }else if(value.parameter_type == $scope.from_params_data.JOURNEY) {
                                            $scope.changeParam($scope.journey_params, value.parameter_id, key);
                                        }
                                        $timeout( function () {
                                            $('#param-'+ key).val(value.parameter_id);
                                            $('#param-'+ key).select2();
                                        }, 200);
                                    }
                                });
                            }, 500);

                        }
                    }
                }
            };

            $scope.saveTransaction = function () {

                if(!$scope.is_restful_api){
                    if($scope.ignore_actions_value.indexOf($scope.transaction_step.action_type) >= 0 && $scope.transaction_step.action_type != 'set_variable'){
                        $scope.transaction_step.parameter_type = $scope.from_params_data.NONE;
                        $scope.transaction_step.parameter_name = null;
                    }

                    if($scope.transaction_step.parameter_type == $scope.from_params_data.NONE){
                        $scope.transaction_step.parameter_id = null;
                    }

                    if($scope.transaction_step.action_type == 'go_to'){
                        var url = $scope.transaction_step.data_object_value.url;
                        $scope.transaction_step.data_object_value.parameters = [];
                        $scope.url_secure = url;
                        $scope.url_valid = true;

                        if (url.includes('{') && url.includes('}')){
                            var rs = $scope.validateUrl(url);
                            if(rs.success){
                                $scope.transaction_step.data_object_value.parameters = rs.data;
                            }else {
                                $scope.url_valid = false;
                                return false;
                            }
                        }
                        $scope.url_secure_valid = $scope.validateUrlSecure();
                        if (!$scope.url_secure_valid){
                            return false;
                        }
                    }
                }else{
                    if($scope.transaction_step.action_type == 'send_request'){
                        var url = $scope.transaction_step.data_object_value.url;
                        $scope.transaction_step.data_object_value.parameters = [];
                        $scope.url_secure = url;
                        $scope.url_valid = true;

                        if (url.includes('{') && url.includes('}')){
                           var rs = $scope.validateUrl(url);
                            if(rs.success){
                               $scope.transaction_step.data_object_value.parameters = rs.data;
                           }else {
                               $scope.url_valid = false;
                               return false;
                           }
                        }
                        $scope.url_secure_valid = $scope.validateUrlSecure();
                        if (!$scope.url_secure_valid){
                            return false;
                        }
                    }

                    if($scope.transaction_step.action_type != 'capture_response_value' && $scope.transaction_step.action_type != 'set_authorization' && $scope.transaction_step.action_type != 'by_pass_basic_auth'){
                        $scope.transaction_step.parameter_type = $scope.from_params_data.NONE;
                        $scope.transaction_step.parameter_name = null;
                    }

                    if($scope.transaction_step.parameter_type == $scope.from_params_data.NONE){
                        $scope.transaction_step.parameter_id = null;
                        if($scope.transaction_step.action_type != 'capture_response_value' && $scope.transaction_step.action_type != 'by_pass_basic_auth')
                            $scope.transaction_step.parameter_name = null;
                    }

                    if($scope.api_action_show_key_value.indexOf($scope.transaction_step.action_type) != -1){
                        if($scope.transaction_step.action_type == 'set_body_parameters'){

                            if($scope.transaction_step.data_object_value.body_type != $scope.body_types['raw json']){
                                $scope.transaction_step.data_object_value.data = $scope.data_attributes;
                            }
                        }else{
                            $scope.transaction_step.data_object_value = $scope.data_attributes;
                        }
                    }else{
                        delete $scope.transaction_step.data_object_value.body_type
                    }
                }

                if ($scope.submit_url != null && $scope.submit_url != '') {
                    $.post($scope.submit_url, {transaction_step: $scope.transaction_step}, function (result) {
                    });
                } else {
                    $.ajax({
                        url: $scope.update_url,
                        type: 'PUT',
                        data: {transaction_step: $scope.transaction_step},
                        success: function (data) {
                        }
                    });
                }

            };

            $scope.changeActionType = function () {
                var action_type = $.trim($scope.transaction_step.action_type);

                if (action_type != '') {

                    if (!$scope.is_restful_api && $scope.ignore_actions_element.indexOf(action_type) == -1) {
                        if (action_type == 'scroll_to') {
                            if (Object.keys($scope.recognized_by_attributes).indexOf($scope.transaction_step.recognized_by_attribute) == -1) {
                                $scope.transaction_step.recognized_by_attribute = null;
                            }
                        }
                        if ($scope.show_action_attribute_value.indexOf(action_type) != -1) {

                            if ($scope.transaction_step.data_object_value != '' && $scope.transaction_step.data_object_value != null && $scope.transaction_step.data_object_value.element != '' && $scope.transaction_step.data_object_value.element != null &&
                                Object.keys($scope.recognized_by_attributes).indexOf($scope.transaction_step.data_object_value.element.object_recognized_by) == -1) {
                                $scope.transaction_step.data_object_value.element.object_recognized_by = null;
                            }
                        }
                    }

                    if($scope.is_restful_api){
                        $scope.is_show_key_value = $scope.api_action_show_key_value.indexOf(action_type) != -1 && action_type != 'set_body_parameters'

                        if($scope.api_action_show_key_value.indexOf(action_type) != -1){
                            if(JSON.stringify($scope.transaction_step.data_object_value) == '{}'){
                                $scope.data_attributes = angular.copy($scope.action_attribute_values[action_type]);
                            }else if(action_type == 'set_body_parameters'){
                                $scope.changeBodyType();
                            }else{
                                if($scope.transaction_step.data_object_value[0]){
                                    $scope.data_attributes = []

                                    Object.keys($scope.transaction_step.data_object_value).forEach(function(key)
                                    {
                                        if(!isNaN(key))
                                            $scope.data_attributes.push($scope.transaction_step.data_object_value[key]);
                                    });
                                }else{
                                    $scope.data_attributes = angular.copy($scope.action_attribute_values[action_type]);
                                }
                            }
                        }
                    }

                }
                $timeout( function(){
                    $('.select2').select2();
                }, 300);
            };

            $scope.validateTransaction = function () {
                if (!$scope.transaction_step && !$scope.transaction_step.action_type) return false;
                var action_type = $scope.transaction_step.action_type;
                var validate = $.trim($scope.transaction_step.name) != '' && $.trim($scope.transaction_step.action_type) != ''
                //web action
                if(!$scope.is_restful_api && action_type){
                    if ($scope.ignore_actions_element.indexOf(action_type) == -1) {
                        validate = validate && $.trim($scope.transaction_step.target_element) != '' &&
                            $.trim($scope.transaction_step.attribute_value) != '';

                        if ($scope.transaction_step.target_element != 'xpath'){
                            validate = validate && $.trim($scope.transaction_step.recognized_by_attribute) != '';
                        }
                    }

                    if ( $scope.ignore_actions_value.indexOf(action_type) == -1) {
                        if($scope.transaction_step.parameter_type == $scope.from_params_data.APP || $scope.transaction_step.parameter_type == $scope.from_params_data.JOURNEY){
                            valid = $.trim($scope.transaction_step.parameter_id) != '';
                        }else if($scope.transaction_step.parameter_type == $scope.from_params_data.TRANSACTION){
                            valid = $.trim($scope.transaction_step.parameter_name) != '';
                        } else{
                            valid = $.trim($scope.transaction_step.data_value) != '';
                        }
                        validate = validate && valid;
                    }

                    if ($.trim($scope.show_action_attribute_value) != '' && $scope.show_action_attribute_value.indexOf(action_type) != -1) {

                        if($.trim($scope.transaction_step.data_object_value) != '' && $scope.action_attribute_values[action_type]['element'] != null){
                            var element_valid = false;
                            if($.trim($scope.transaction_step.data_object_value.element) != ''){
                                element_valid = $.trim($scope.transaction_step.data_object_value.element.element_type) != '' &&
                                    $.trim($scope.transaction_step.data_object_value.element.object_value) != '';

                                if ($.trim($scope.transaction_step.data_object_value.element.element_type) != '' && $.trim($scope.transaction_step.data_object_value.element.element_type) != 'xpath'){
                                    element_valid = element_valid && $.trim($scope.transaction_step.data_object_value.element.object_recognized_by) != '';
                                }

                                if($scope.action_attribute_values[action_type]['element']['attribute'] != null){
                                    element_valid = element_valid && $.trim($scope.transaction_step.data_object_value.element.attribute) != ''
                                        && $.trim($scope.transaction_step.data_object_value.element.attribute_value) != '' ;
                                }else if ($scope.action_attribute_values[action_type]['element']['css'] != null){
                                    element_valid = element_valid && $.trim($scope.transaction_step.data_object_value.element.css) != ''
                                        && $.trim($scope.transaction_step.data_object_value.element.css_value) != '' ;
                                }
                            }

                            validate = validate && element_valid;
                        }

                        if($.trim($scope.transaction_step.data_object_value) != '' && $scope.action_attribute_values[$scope.transaction_step.action_type]['to_object'] != null){
                            var to_object_valid = false;

                            if($scope.transaction_step.data_object_value.to_object != null){
                                to_object_valid = $.trim($scope.transaction_step.data_object_value.to_object.element_type) != ''
                                    && $.trim($scope.transaction_step.data_object_value.to_object.object_value) != '';

                                if ( $.trim($scope.transaction_step.data_object_value.to_object.element_type) != '' && $.trim($scope.transaction_step.data_object_value.to_object.element_type) != 'xpath'){
                                    to_object_valid = to_object_valid && $.trim($scope.transaction_step.data_object_value.to_object.object_recognized_by) != '';
                                }
                            }
                            validate = validate && to_object_valid;
                        }

                        if($.trim($scope.transaction_step.data_object_value) != '' && $scope.action_attribute_values[$scope.transaction_step.action_type]['over_cross_element'] != null){
                            var over_cross_element_valid = false;

                            if($scope.transaction_step.data_object_value.over_cross_element != null){
                                over_cross_element_valid = $.trim($scope.transaction_step.data_object_value.over_cross_element.element_type) != ''
                                    && $.trim($scope.transaction_step.data_object_value.over_cross_element.object_value) != '';

                                if ( $.trim($scope.transaction_step.data_object_value.over_cross_element.element_type) != '' && $.trim($scope.transaction_step.data_object_value.over_cross_element.element_type) != 'xpath'){
                                    over_cross_element_valid = over_cross_element_valid && $.trim($scope.transaction_step.data_object_value.over_cross_element.object_recognized_by) != '';
                                }
                            }
                            validate = validate && over_cross_element_valid;
                        }

                        if($.trim($scope.transaction_step.data_object_value) != '' && $scope.action_attribute_values[$scope.transaction_step.action_type]['position'] != null ){
                            var position_valid = false;

                            if($scope.transaction_step.data_object_value.position != null){
                                position_valid = $.trim($scope.transaction_step.data_object_value.position.x) != '' && $.trim($scope.transaction_step.data_object_value.position.y) != '';
                            }
                            validate = validate && position_valid;
                        }
                    }

                    if (action_type == 'set_variable'){
                        validate = validate && $.trim($scope.transaction_step.parameter_name) != ''
                            && $.trim($scope.transaction_step.data_object_value.selector_property) != ''
                            && $.trim($scope.transaction_step.data_object_value.element_type) != ''
                            && $.trim($scope.transaction_step.data_object_value.object_value) != '';
                        if ($.trim($scope.transaction_step.data_object_value.element_type) != '' && $scope.transaction_step.data_object_value.element_type != 'xpath'){
                            validate = validate && $.trim($scope.transaction_step.data_object_value.object_recognized_by) != '';
                        }
                    }

                    if (action_type == 'by_pass_basic_auth'){
                        validate = validate && $.trim($scope.transaction_step.data_object_value.username) != '' && $.trim($scope.transaction_step.data_object_value.password) != '';
                    }

                    if (action_type == 'go_to'){
                        validate = validate && $.trim($scope.transaction_step.data_object_value.url) != '';
                    }

                    if (action_type == 'set_screen_size'){
                        validate = validate && $.trim($scope.transaction_step.data_object_value.horizontal) != '' && $.trim($scope.transaction_step.data_object_value.vertical) != '';
                    }

                    if ($scope.show_index_value.indexOf(action_type) != -1){
                        validate = validate && $.trim($scope.transaction_step.data_value) != '';
                    }

                }
                // api action
                if($scope.is_restful_api && action_type){

                    if ($scope.api_action_show_key_value.indexOf($scope.transaction_step.action_type) != -1){
                        var is_set_param_type = true;

                        if(action_type == 'set_body_parameters'){
                            validate = $scope.transaction_step.data_object_value.body_type != null;

                            if($scope.transaction_step.data_object_value.body_type == $scope.body_types['raw json']){
                                is_set_param_type = false;
                                validate = $scope.isJson($scope.transaction_step.data_object_value.data);
                            }
                        }

                        if(is_set_param_type){
                            var keys = [];
                            $scope.input_valid = [];

                            for(var i = 0; i < $scope.data_attributes.length; i++){
                                var is_json = false;
                                var data_attribute = $scope.data_attributes[i];
                                var dup_keys = keys.find(element => element == data_attribute.key);
                                keys.push(data_attribute.key);
                                if(data_attribute.parameter_type == $scope.from_params_data.APP || data_attribute.parameter_type == $scope.from_params_data.JOURNEY){
                                    valid = $.trim(data_attribute.parameter_id) != '';
                                }else if(data_attribute.parameter_type == $scope.from_params_data.TRANSACTION){
                                    valid = $.trim(data_attribute.parameter_name) != '';
                                } else{
                                    valid = $.trim(data_attribute.value) != '' && $.trim(data_attribute.value) != '[]' && $.trim(data_attribute.value) != '""' && $.trim(data_attribute.value) != '{}';

                                    if($scope.transaction_step.data_object_value.body_type == $scope.body_types['form-data (parse json)'] && valid && data_attribute.value.includes('{')){
                                        is_json = true;
                                        valid = $scope.isJson(data_attribute.value);
                                    }
                                }
                                data_attribute.is_json = is_json;
                                $scope.input_valid.push([valid, is_json]);
                                $scope.is_key_duplicate = dup_keys != null;
                                validate = validate && $.trim(data_attribute.key) != '' && valid && !$scope.key_duplicate;

                                if(!validate) break;
                            }
                        }
                    }

                    if ($scope.transaction_step.action_type == 'send_request'){
                        validate = validate && ($.trim($scope.transaction_step.data_object_value.method) != '' && $.trim($scope.transaction_step.data_object_value.url) != '');
                    }

                    if ($scope.transaction_step.action_type == 'verify_response_code'){
                        validate = validate && $.trim($scope.transaction_step.data_object_value.code) != '';
                    }

                    if ($scope.transaction_step.action_type == 'verify_response_data'){
                        validate = validate && $.trim($scope.transaction_step.data_object_value.data) != '';
                    }

                    if ($scope.transaction_step.action_type == 'verify_response_data_value'){
                        validate = validate && ($.trim($scope.transaction_step.data_object_value.data) != '' && $.trim($scope.transaction_step.data_object_value.value) != '');
                    }

                    if ($scope.transaction_step.action_type == 'capture_response_value'){
                        validate = validate && ($.trim($scope.transaction_step.parameter_name) != '' && $.trim($scope.transaction_step.data_value) != '');
                    }

                    if ($scope.transaction_step.action_type == 'set_authorization'){

                        if($scope.transaction_step.data_object_value.auth_type == $scope.auth_types['Basic authorization']){
                            validate = validate && $.trim($scope.transaction_step.data_object_value.username) != ''
                            && $.trim($scope.transaction_step.data_object_value.password) != '';
                        }else{

                            if($scope.transaction_step.parameter_type == $scope.from_params_data.APP || $scope.transaction_step.parameter_type == $scope.from_params_data.JOURNEY){
                                valid = $.trim($scope.transaction_step.parameter_id) != '';
                            }else if($scope.transaction_step.parameter_type == $scope.from_params_data.TRANSACTION){
                                valid = $.trim($scope.transaction_step.parameter_name) != '';
                            } else{
                                valid = $.trim($scope.transaction_step.data_object_value.username) != '';
                            }
                            validate = validate && valid;
                        }
                    }

                    if (action_type == 'by_pass_basic_auth'){

                        validate = validate && $.trim($scope.transaction_step.data_object_value.url) != '' && $.trim($scope.transaction_step.parameter_name) != ''
                            && $.trim($scope.transaction_step.data_object_value.username) != '' && $.trim($scope.transaction_step.data_object_value.password) != '';
                    }

                    if ($scope.transaction_step.action_type == 'delay'){
                        validate = validate && $.trim($scope.transaction_step.data_value) != '';
                    }
                }

                return validate;
            };

            $scope.changeFromParam = function (index = null) {
                $scope.param_default_value = '';
                $scope.transaction_step.parameter_id = null;
                $scope.transaction_step.data_value = '';
                if(index != null){
                    $timeout( function () {$("#param-"+index+" option[value='?']").remove();}, 100);
                }else{
                    $timeout( function () {$("select option[value='?']").remove();}, 100);
                }
            };

            $scope.changeParam = function (array,id,index = null) {
                if($scope.is_restful_api && $scope.transaction_step.action_type != 'set_authorization' ){
                    $scope.data_attributes[index].param_default_value = null;
                    $scope.data_attributes[index].is_encrypt = null;
                    for (var i = 0; i < array.length; i++) {
                        if (array[i]['id'] === id) {
                            $scope.data_attributes[index].param_default_value = array[i]['default_value'];
                            $scope.data_attributes[index].is_encrypt = array[i]['encrypt'];
                        }
                    }
                }else {
                    $scope.param_default_value = '';
                    for (var i = 0; i < array.length; i++) {
                        if (array[i]['id'] === id) {
                            $scope.param_default_value = array[i]['default_value'];
                            $scope.is_encrypt = array[i]['encrypt'];
                        }
                    }
                }
            };

            $scope.addChild = function () {
                var action_type = $.trim($scope.transaction_step.action_type);
                var data = $scope.action_attribute_values[action_type][0];

                if(action_type == 'set_body_parameters' && $scope.transaction_step.data_object_value.body_type != $scope.body_types['raw json'])
                    data = $scope.action_attribute_values[action_type].data[0]
                $scope.data_attributes.push(angular.copy(data));

            };

            $scope.changeBodyType = function () {
                $scope.is_show_key_value = $scope.transaction_step.data_object_value.body_type != null && $scope.transaction_step.data_object_value.body_type != $scope.body_types['raw json'];

                if($scope.is_show_key_value){
                    $scope.data_attributes = angular.copy($scope.action_attribute_values[$scope.transaction_step.action_type].data);
                    var check_current_action_type = $scope.api_action_show_key_value.indexOf($scope.current_action_type) != -1;

                    if($scope.transaction_step.data_object_value.data && $scope.transaction_step.data_object_value.data[0] != '{' && check_current_action_type){
                        $scope.data_attributes = [];
                        Object.keys($scope.transaction_step.data_object_value.data).forEach(function(key) {
                            if(!isNaN(key))
                                $scope.data_attributes.push($scope.transaction_step.data_object_value.data[key]);
                        });
                    }
                }else{
                    $timeout( $('#json_data').val(''),600);
                }
            };

            $scope.removeChild = function (index) {
                $scope.data_attributes.splice(index,1);
            };

            $scope.validateUrl = function (url, secure_check = false) {
                var pattern_items = url.split('{');
                var params = [];
                var is_valid = true;
                angular.forEach(pattern_items, function(item) {
                    if(item.includes('}') && $scope.url_valid){
                        var rs = $scope.validateParam(item.split('}')[0]);
                        if(rs.success){
                            params.push({
                                'parameter_id': rs.id,
                                'parameter_name': item.split('}')[0],
                                'parameter_type': rs.param_type
                            });

                            if(rs.default_value) {
                                var param_name = '{' + item.split('}')[0] + '}'
                                $scope.url_secure = $scope.url_secure.replace(param_name, rs.default_value);
                            }
                        }else{
                            is_valid = false;
                        }
                    }
                });
                return {'success': is_valid, 'data': params}
            };

            $scope.validateParam = function (param_name) {
                var validate = false
                var param_type, id, default_value = null;

                angular.forEach($scope.app_params, function(item) {
                    if(!validate && item.name == param_name){
                        validate = true;
                        id = item.id;
                        param_type = $scope.from_params_data.APP;
                        default_value = item.default_value;
                    }
                });

                if (!validate){
                    angular.forEach($scope.journey_params, function(item) {
                        if(!validate && item.name == param_name){
                            validate = true;
                            id = item.id;
                            param_type = $scope.from_params_data.JOURNEY;
                            default_value = item.default_value;
                        }
                    });
                }

                if(!validate){
                    angular.forEach($scope.trans_parameter_names, function(item) {
                        if(!validate && item[1] == param_name){
                            validate = true;
                            id = item[0]
                            param_type = $scope.from_params_data.TRANSACTION;
                        }
                    });
                }
                return {'success': validate, 'id': id, 'param_type': param_type, 'default_value': default_value}
            };

            $scope.isJson = function (str) {
                try {
                    JSON.parse(str);
                } catch (e) {
                    return false;
                }
                return true;
            };

            $scope.validateUrlSecure = function () {
                // var regex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
                var is_valid = true;

                if ($scope.url_secure.includes('?')){
                    var index = $scope.url_secure.indexOf('?');
                    var url_params = $scope.url_secure.slice(index);
                    if(url_params.includes('=')){
                        angular.forEach(url_params.split('&'), function(item) {
                            var param_name = item.slice(0,item.indexOf('=')).toLowerCase();
                            if ($scope.sensitive_keys.some(v => param_name.includes(v))) {
                                is_valid = false
                            }
                        });
                    }
                }

                $scope.url_sensitive_valid = is_valid;
                return ($scope.url_secure.indexOf("http://") == 0 || $scope.url_secure.indexOf("https://") == 0) && is_valid;
                // return regex.test($scope.url_secure) && is_valid;
            };
        }
    ]);

}).call(this);
