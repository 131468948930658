(function () {
    app.controller('DashboardCtrl', [
        "$scope", '$timeout',
        function ($scope, $timeout) {
            $scope.intervals = {};
            $scope.interval = null;
            $scope.intervals = {
                '2H': {value: 0, step: 1},
                '12H': {value: 1, step: 1},
                'D': {value: 2, step: 1},
                'W': {value: 3, step: 1},
                //'M': {value: 4, step: 1},
                //'CALENDAR': {value: 5, step: 1}
            };
            $scope.interval = null;
            $scope.data = {};
            $scope.search_app = '';

            $scope.init = new function () {
                $scope.language = window.getCookie('locale');

                if($scope.language == 'en'){
                    $scope.intervals_name = {
                        '2H': '2H',
                        '12H': '12H',
                        'D': 'D',
                        'W': 'W',
                        //'M': 'M',
                        //'CALENDAR': ''
                    };
                    $scope.intervals_full_name = {
                        'H': {single: 'H', plural: 'H'},
                        'D': {single: 'day', plural: 'days'},
                        'W': {single: 'week', plural: 'weeks'},
                        'M': {single: 'month', plural: 'months'}
                    };
                }else{
                    $scope.intervals_name = {
                        '2H': '2 Giờ',
                        '12H': '12 Giờ',
                        'D': 'Ngày',
                        'W': 'Tuần',
                        //'M': 'Tháng',
                        //'CALENDAR': ''
                    };
                    $scope.intervals_full_name = {
                        'H': {single: 'giờ', plural: 'giờ'},
                        'D': {single: 'ngày', plural: 'ngày'},
                        'W': {single: 'tuần', plural: 'tuần'},
                        'M': {single: 'tháng', plural: 'tháng'}
                    };
                }
            };

            $scope.selectInterval = function(val){
                $scope.interval = val;
                $scope.interval.step = 1;
                $scope.changeInterval();
            };

            $scope.previousInterval = function(){
                $scope.interval.step += 1;
                $scope.changeInterval();
            };

            $scope.nextInterval = function(){
                $scope.interval.step -= 1;

                if($scope.interval.step < 1){
                    $scope.interval.step = 1;
                }
                $scope.changeInterval();
            };

            $scope.changeInterval = function(){
                var has_prefix_text = true;
                var period = 0;
                var interval = $scope.interval.value;
                var step = $scope.interval.step;
                var current_date = moment();
                $scope.interval.to_date = moment();

                if(interval == $scope.intervals['2H'].value){
                    period = step * 2;
                    $scope.interval.from_date = moment().subtract(period, 'hour');
                    $scope.interval.text = period + ' ' + $scope.intervals_full_name['H'].single;
                }else if(interval == $scope.intervals['12H'].value){
                    period = step * 12;
                    $scope.interval.from_date = current_date.subtract(period, 'hour');
                    $scope.interval.text = period + ' ' + $scope.intervals_full_name['H'].single;
                }else if(interval == $scope.intervals['D'].value){
                    period = step * 1;
                    $scope.interval.from_date = current_date.subtract(period, 'day');
                    var n = $scope.intervals_full_name['D'];
                    $scope.interval.text = period + ' ' + (period > 1 ? n.plural : n.single);
                }else if(interval == $scope.intervals['W'].value){
                    period = step * 1;
                    $scope.interval.from_date = current_date.subtract(period, 'week');
                    var n = $scope.intervals_full_name['W'];
                    $scope.interval.text = period + ' ' + (period > 1 ? n.plural : n.single);
                }else if(interval == $scope.intervals['M'].value){
                    period = step * 1;
                    $scope.interval.from_date = current_date.subtract(period, 'month');
                    var n = $scope.intervals_full_name['M'];
                    $scope.interval.text = period + ' ' + (period > 1 ? n.plural : n.single);
                }else if(interval == $scope.intervals['CALENDAR'].value){
                    has_prefix_text = false;
                    $scope.interval.from_date = current_date.subtract(2, 'week');
                    $scope.interval.text = $scope.interval.from_date.format('YYYY-MM-DD') + ' - ' + $scope.interval.to_date.format('YYYY-MM-DD');
                }

                if(has_prefix_text){
                    if($scope.language == 'en'){
                        $scope.interval.text = 'Previous ' + $scope.interval.text;
                    }else{
                        $scope.interval.text += ' trước';
                    }
                }

                $scope.getData();
            };

            $scope.getData = function () {
                var from_date = moment($scope.interval.from_date).utc().format('YYYY-MM-DD HH:mm:ss Z');
                var to_date = moment($scope.interval.to_date).utc().format('YYYY-MM-DD HH:mm:ss Z');
                var opt = { from_date: from_date, to_date: to_date, q: $scope.search_app};

                $.post('/dashboard', opt, function(rs){
                    if(rs.success){
                        $timeout(function(){
                            $scope.data = rs.data;
                        });
                    }
                });
            };
        }
    ]);

}).call(this);
