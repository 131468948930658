(function () {
    app.controller('ScheduleCtrl', [
        "$scope", '$timeout',
        function ($scope, $timeout) {
            $scope.agents = {};
            $scope.parameters = {};
            $scope.schedule_params = {};
            $scope.selected_schedule_id = null;

            $scope.init = function () {
            };

            $scope.selectSchedule = function() {
                if(!$scope.selected_schedule_id){
                    return;
                }

                var url = $scope.get_schedule_data_url;
                url = url.replace('_schedule_id_', $scope.selected_schedule_id);

                $.get(url, function (data) {
                    $timeout(function () {
                        if(data.success){
                            $scope.agents = data.agents;
                            $scope.parameters = data.parameters;
                            $scope.schedule_params = data.schedule_params;
                        }else{
                            $scope.agents = {};
                            $scope.parameters = {};
                        }

                    });
                });
            };

            $scope.saveData = function(){
                if($scope.validate()){
                    var url = $scope.submit_url;
                    url = url.replace('_schedule_id_', $scope.selected_schedule_id);
                    var option = { schedule_params: angular.toJson($scope.schedule_params) };

                    $.post(url, option, function (result) {
                        if(result.success){
                            toastr.success(result.message);
                        }else{
                            $timeout(function () {
                                if(result.schedule_params){
                                    $scope.schedule_params = result.schedule_params;
                                }
                            });
                        }
                    });
                }
            };

            $scope.validate = function(){
                var is_valid = true;
                $.each($scope.schedule_params, function(k,v){
                    $.each(v, function(i,p){
                        p.error = ($.trim(p.parameter_value) == '' ? 'Cannot be blank!' : '');

                        if(is_valid && p.error != ''){
                            is_valid = false;
                        }
                    });
                });

                return is_valid;
            };
        }
    ]);
}).call(this);
