// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require('turbolinks').start();
require("@rails/activestorage").start();
require("channels");

import jquery from 'jquery';
window.$ = window.jquery = jquery;
window.jQuery = window.jquery = jquery;
require("packs/base/jquery-ui");

import 'bootstrap';
import "@fortawesome/fontawesome-free/js/all";
import 'select2';

global.bootbox = require('bootbox');
global.angular = require('angular');
require('moment');
require('angular-route');
require("packs/base/init");
require("packs/base/angular_app");
require("packs/base/angular_utils");
require('angular-nvd3');

global.toastr = require('toastr');
global.app = angular.module('app');
require("packs/pages/app_dashboard_ctrl");
require("packs/pages/dashboard_ctrl");
require("packs/pages/schedule_ctrl");
require("packs/pages/transaction_step_ctrl");
require("packs/pages/agent_pool_ownerships_ctrl");
require("packs/pages/guideline_ctrl");

