(function () {
    app.controller('AgentPoolOwnershipsCtrl', ["$scope", '$timeout', function ($scope, $timeout) {
        $scope.app_keyword = null;
        $scope.apps = [];
        $scope.app = {};
        $scope.is_searched_app = false;
        $scope.selected_included_pools = [];
        $scope.selected_available_pools = [];
        $scope.message = '';
        $scope.is_validated = false;
        $scope.is_executed = false;

        $scope.init = function () {
        };

        $scope.searchApp = function ($event) {
            $scope.message = '';
            $scope.is_validated = false;
            $scope.is_executed = false;

            if ($event.keyCode != 13 && $event.type != 'click') {
                return false;
            }

            $scope.apps = [];
            $scope.app = {};
            $scope.is_searched_app = false;
            var keyword = $.trim($scope.app_keyword);

            if(keyword.indexOf('#') > -1){
                keyword = keyword.replaceAll('#','');
                keyword = $.trim(keyword);
                $scope.app_keyword = keyword;
            }

            if (keyword != '') {
                $.get('/agent_pools/ownership?q=' + keyword, function (rs) {
                    $timeout(function () {
                        if (rs) {
                            $scope.apps = rs.apps;
                        }
                        $scope.is_searched_app = true;
                    });
                });
            }
        };

        $scope.selectApp = function(app) {
            $scope.message = '';
            $scope.is_validated = false;
            $scope.is_executed = false;
            
            if( $scope.app.id == app.id){
                return false;
            }

            $scope.app = app;
            if ($scope.app && $scope.app.id) {
                $.get('/agent_pools/ownership?app_id=' + $scope.app.id, function (rs) {
                    $timeout(function () {
                        if (rs) {
                            $scope.app.included_pools = rs.included_pools;
                            $scope.app.available_pools = rs.available_pools;
                        }
                        $scope.is_searched_app = true;
                    });
                });
            }
        };

        $scope.addAgentPools = function() {
            var available_pools = angular.copy($scope.app.available_pools);
            var pool_ids = [];
            $.each(available_pools, function(i,x){
                if(x.selected){
                    x.selected = false;
                    $scope.app.included_pools.push(x);
                    pool_ids.push(x.id);
                }
            });

            $scope.app.available_pools = $.grep($scope.app.available_pools, function(x,i){return pool_ids.indexOf(x.id) < 0;});
        };

        $scope.removeAgentPools = function() {
            var included_pools = angular.copy($scope.app.included_pools);
            var pool_ids = [];
            $.each(included_pools, function(i,x){
                if(x.selected){
                    x.selected = false;
                    $scope.app.available_pools.push(x);
                    pool_ids.push(x.id);
                }
            });

            $scope.app.included_pools = $.grep($scope.app.included_pools, function(x,i){return pool_ids.indexOf(x.id) < 0;});
        };

        $scope.validateOwnerships = function(){
            saveOwnerships(false);
        };

        $scope.confirmOwnerships = function(){
            saveOwnerships(true);
        };

        $scope.cancelOwnerships = function(){
            $scope.is_validated = false;
            $scope.is_executed = false;
            $scope.message = '';
            $scope.selectApp($scope.app);
        };

        function saveOwnerships(is_executed) {
            $scope.message = '';
            if ($scope.app && $scope.app.id) {
                var opt = {
                    app_id: $scope.app.id,
                    included_pools: angular.toJson($scope.app.included_pools),
                    is_executed: is_executed
                }
                $.post('/agent_pools/ownership_execution', opt, function (rs) {
                    $timeout(function () {
                        $scope.message = rs.message;
                        $scope.is_executed = rs.is_executed;
                        $scope.is_validated = rs.succeed;
                        
                        if(rs.succeed && rs.is_executed){
                            $scope.app_keyword = null;
                            $scope.is_searched_app = false;
                            $scope.apps = [];
                            $scope.app = {};
                            $scope.selected_included_pools = [];
                            $scope.selected_available_pools = [];
                            $scope.is_validated = false;
                        }
                    });
                });
            }
        };
    }]);
}).call(this);