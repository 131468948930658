(function () {
    app.controller('AppDashboardCtrl', [
        "$scope", '$timeout',
        function ($scope, $timeout) {
            $scope.journey_id = null;
            $scope.intervals = {};
            $scope.interval = null;
            $scope.options = {};
            $scope.intervals = {
                '2H': {value: 0, step: 1},
                '12H': {value: 1, step: 1},
                'D': {value: 2, step: 1},
                'W': {value: 3, step: 1},
                //'M': {value: 4, step: 1},
                //'CALENDAR': {value: 5, step: 1}
            };
            $scope.interval = null;
            $scope.summary_data = {};
            $scope.time_unit = 'h';
            $scope.chart_options = {
                chart: {
                    type: 'multiChart',
                    height: 550,
                    margin : {
                        top: 50,
                        right: 50,
                        bottom: 85,
                        left: 50
                    },
                    xAxis: {
                        tickFormat: function(d){
                            if(d != null && d != NaN){
                                d = d * 1000;
                                var date = (new Date(d));
                                //convert to UTC
                                var offsetInMs = (date.getTimezoneOffset() * 60 * 1000);
                                var utcDate = new Date(date.getTime() + offsetInMs);
                                var interval = $scope.interval.value;

                                if($scope.time_unit == 'h'){
                                    return d3.time.format('%H:%M')(utcDate);
                                }else{
                                    if(interval == $scope.intervals['W'].value){
                                        return d3.time.format('%a')(utcDate);
                                    }else{
                                        return d3.time.format('%Y-%m-%d')(utcDate);
                                    }
                                }
                            }
                        },
                        axisLabel: 'UTC',
                        axisLabelDistance: 40,
                        tickPadding: 0,
                        tickSize: 0,
                        rotateLabels: -45
                    },
                    yAxis1: {
                        tickFormat: function(d){
                            return (d3.format(',.2f')(d)) + 's';
                        },
                        ticks: 10
                    },
                    yAxis2: {
                        tickFormat: function(d){
                            return (d3.format(',.2f')(d)) + '%';
                        },
                        ticks: 10
                    },
                    yDomain1: [0, 15],
                    yDomain2: [0, 100],
                    bars: {
                        showValues: true,
                        groupSpacing: 0,
                        margin: {
                            top:0,
                            bottom: 0,
                            left: 0,
                            right: 0
                        }
                    },
                    bars1: {
                        showValues: true,
                        groupSpacing: 0,
                        margin: {
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0
                        }
                    },
                    legend: {
                        align: false,
                        margin: {top: 5, bottom: 5}
                    }
                }
            };
            $scope.chart_data = [];

            $scope.init = new function () {
                $scope.language = window.getCookie('locale');

                if($scope.language == 'en'){
                    $scope.intervals_name = {
                        '2H': '2H',
                        '12H': '12H',
                        'D': 'D',
                        'W': 'W',
                        //'M': 'M',
                        //'CALENDAR': ''
                    };
                    $scope.intervals_full_name = {
                        'H': {single: 'H', plural: 'H'},
                        'D': {single: 'day', plural: 'days'},
                        'W': {single: 'week', plural: 'weeks'},
                        'M': {single: 'month', plural: 'months'}
                    };
                }else{
                    $scope.intervals_name = {
                        '2H': '2 Giờ',
                        '12H': '12 Giờ',
                        'D': 'Ngày',
                        'W': 'Tuần',
                        //'M': 'Tháng',
                        //'CALENDAR': ''
                    };
                    $scope.intervals_full_name = {
                        'H': {single: 'giờ', plural: 'giờ'},
                        'D': {single: 'ngày', plural: 'ngày'},
                        'W': {single: 'tuần', plural: 'tuần'},
                        'M': {single: 'tháng', plural: 'tháng'}
                    };
                }
            };

            $scope.selectInterval = function(val){
                $scope.interval = val;
                $scope.interval.step = 1;
                $scope.changeInterval()
            };

            $scope.previousInterval = function(){
                $scope.interval.step += 1;
                $scope.changeInterval();
            };

            $scope.nextInterval = function(){
                $scope.interval.step -= 1;

                if($scope.interval.step < 1){
                    $scope.interval.step = 1;
                }
                $scope.changeInterval();
            };

            $scope.changeInterval = function(){
                var has_prefix_text = true;
                var period = 0;
                var interval = $scope.interval.value;
                var step = $scope.interval.step;
                var current_date = moment();
                $scope.interval.to_date = moment();

                if(interval == $scope.intervals['2H'].value){
                    period = step * 2;
                    $scope.interval.from_date = moment().subtract(period, 'hour');
                    $scope.interval.text = period + ' ' + $scope.intervals_full_name['H'].single;
                }else if(interval == $scope.intervals['12H'].value){
                    period = step * 12;
                    $scope.interval.from_date = current_date.subtract(period, 'hour');
                    $scope.interval.text = period + ' ' + $scope.intervals_full_name['H'].single;
                }else if(interval == $scope.intervals['D'].value){
                    period = step * 1;
                    $scope.interval.from_date = current_date.subtract(period, 'day');
                    var n = $scope.intervals_full_name['D'];
                    $scope.interval.text = period + ' ' + (period > 1 ? n.plural : n.single);
                }else if(interval == $scope.intervals['W'].value){
                    period = step * 1;
                    $scope.interval.from_date = current_date.subtract(period, 'week');
                    var n = $scope.intervals_full_name['W'];
                    $scope.interval.text = period + ' ' + (period > 1 ? n.plural : n.single);
                }else if(interval == $scope.intervals['M'].value){
                    period = step * 1;
                    $scope.interval.from_date = current_date.subtract(period, 'month');
                    var n = $scope.intervals_full_name['M'];
                    $scope.interval.text = period + ' ' + (period > 1 ? n.plural : n.single);
                }else if(interval == $scope.intervals['CALENDAR'].value){
                    has_prefix_text = false;
                    $scope.interval.from_date = current_date.subtract(2, 'week');
                    $scope.interval.text = $scope.interval.from_date.format('YYYY-MM-DD') + ' - ' + $scope.interval.to_date.format('YYYY-MM-DD');
                }

                if(has_prefix_text){
                    if($scope.language == 'en'){
                        $scope.interval.text = 'Previous ' + $scope.interval.text;
                    }else{
                        $scope.interval.text += ' trước';
                    }
                }

                $scope.getData();
            };

            $scope.getData = function(){
                var from_date = moment($scope.interval.from_date).utc().format('YYYY-MM-DD HH:mm:ss Z');
                var to_date = moment($scope.interval.to_date).utc().format('YYYY-MM-DD HH:mm:ss Z');
                var time_unit = [$scope.intervals['2H'].value, $scope.intervals['12H'].value].indexOf($scope.interval.value) >= 0 ? 'h' : 'd';

                var opt = {
                    journey_id: $scope.journey_id,
                    from_date: from_date,
                    to_date: to_date,
                    time_unit: time_unit
                };
                var url = '/apps/' + $scope.app_id + '/get_dashboard_data';

                $.post(url, opt, function(rs){
                    if(rs.success){
                        var d = rs.data;
                        $timeout(function(){
                            $scope.summary_data = d.summary_data;
                            $scope.chart_data = d.chart_data;
                            $scope.chart_options.chart.yDomain1[1] = $scope.summary_data.max_avg_response_time > 10 ? $scope.summary_data.max_avg_response_time : 10;
                            $scope.chart_options.chart.xAxis.tickValues = d.ticks;
                            $scope.time_unit = d.time_unit;
                        });
                    }
                });
            };

            $scope.getSampleData = function(){
                var interval = $scope.interval.value;

                if(interval == $scope.intervals['2H'].value){
                    generateSampleChartData(24,5, 'minute');
                }else if(interval == $scope.intervals['12H'].value){
                    generateSampleChartData(12,1, 'hour');
                }else if(interval == $scope.intervals['D'].value){
                    generateSampleChartData(24,1, 'hour');
                }else if(interval == $scope.intervals['W'].value){
                    generateSampleChartData(7,1, 'day');
                }else if(interval == $scope.intervals['M'].value){
                    generateSampleChartData(30,1, 'day');
                }else if(interval == $scope.intervals['CALENDAR'].value) {
                    generateSampleChartData(10,1, 'hour');
                }

            };

            function generateSampleChartData(numberPoints, period, interval){
                var interval_date = $scope.interval.date.clone();
                var data0 = [];
                var data1 = [];
                var data2 = [];
                var sum0 = 0, sum1 = 0, sum2 = 0;

                for (var i = 0; i < numberPoints; i++) {
                    interval_date = interval_date.add(period, interval);
                    var y0 = Math.floor(Math.random() * 10) + 90; // availability: random number from 90..100
                    var y1 = Math.floor(Math.random() * 10) + 1; // response time: random number from 1..10
                    var y2 = Math.floor(Math.random() * 20); // //error count: random number from 0..20

                    sum0 += y0;
                    sum1 += y1;
                    sum2 += y2;

                    data0.push({
                        x: interval_date.toDate(),
                        y: y0
                    });
                    data1.push({
                        x: interval_date.toDate(),
                        y: y1
                    });
                    data2.push({
                        x: interval_date.toDate(),
                        y: y2,
                        label: y2
                    });
                }

                $scope.summary_data.average_availability = (sum0 * 1.0 / numberPoints).toFixed(2);
                $scope.summary_data.average_response = (sum1 * 1.0 / numberPoints).toFixed(2);
                $scope.summary_data.total_failures = (sum2 * 1.0 / numberPoints).toFixed(2);

                $scope.chart_data = [
                    {"color":"#F09137","key":"response","originalKey":"Response (sec)","type":"line","values": [], "yAxis":1},
                    {"color":"#54A4F3","key":"availability","originalKey":"Availability (%)","type":"line","values": [], "yAxis":2},
                    {"color":"#E65660","key":"total_failures","originalKey":"Failures Ratio","type":"bar","values": [], "yAxis":2}
                ];

                $scope.chart_data[0].values = data1;
                $scope.chart_data[1].values = data0;
                $scope.chart_data[2].values = data2;
            }
        }
    ]);

}).call(this);
